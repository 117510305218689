// Footer Links
export const TWITTER_PAGE = 'https://twitter.com/unbiased_ml';
export const FACEBOOK_PAGE = 'https://www.facebook.com/unbiased.ml';
export const LINKEDIN_PAGE = 'https://www.linkedin.com/company/unbiased';
export const TELEGRAM_PAGE = 'https://t.me/unbiased_ml';
export const YOUTUBE_CHANNEL = 'https://www.youtube.com/channel/UC6i3DSWaleDUMp1CF1ERwlA';
export const VIMEO_CHANNEL = 'https://vimeo.com/unbiased';

// Workforce Link
export const WORKFORCE_WEBSITE = 'https://workforce.unbiased.ml/';
export const WORKFORCE_APP_STORE = 'https://apps.apple.com/tt/app/unbiased-workforce/id1502361378';
export const WORKFORCE_PLAY_STORE = 'https://play.google.com/store/apps/details?id=com.unbiased&hl=en';

// Links
// export const UNBIASED_VIDEO = "https://youtu.be/w3YhIcx9544";
export const UNBIASED_VIDEO = "https://www.youtube.com/embed/w3YhIcx9544"
export const CONTACT_US = "https://share.hsforms.com/1oRza2Ek0RLif86gUW6IShw4uiqy"

//Innovations
export const UNBIASED_CC = "https://unbiased.cc/"