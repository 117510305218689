import React, { useState } from "react"
import PropTypes from "prop-types"
import Modal from '@material-ui/core/Modal';
import {
    CONTACT_US
} from '../config/constants';
import { makeStyles } from '@material-ui/core/styles';
import './header.scss';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '100%'
    },
}));

const ContactModal = ({ open, handleClose, source }) => {
    const classes = useStyles();
    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={handleClose}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
        >
            <iframe className="iframeView" src={source ?
                source : CONTACT_US}></iframe>
        </Modal>
    )
}

ContactModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func
}

ContactModal.defaultProps = {
    siteTitle: false,
}

export default ContactModal
